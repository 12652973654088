import React from 'react';
import PropTypes from 'prop-types';
import Button from '@glowforge/gf-styled/src/components/button'
import Markdown from '@glowforge/gf-styled/src/components/markdown';

import { 
  FinePrint,
  FormContainer,
} from '../shared.styles'

import {
  ThankYouBody,
  ThankYouModalActions,
  ThankYouCloseLink,
} from './thanks-you.styles'

const ThankYou = ({
  getString,
  onClose,
  strings,
  modal,
}) => (
  <FormContainer className='thank-you-step' id='swag'>
    <ThankYouBody>
      <Markdown source={getString(strings, 'thank-you-body')} />
    </ThankYouBody>
    <ThankYouModalActions>
      <Button
        icon={null}
        className='thank-you-redirect'
        onClick={onClose || null}
        href={getString(strings, 'thank-you-redirect-url')}
        rel='noopener'
        target='_blank'>
        {getString(strings, 'thank-you-redirect-label')}
      </Button>
    </ThankYouModalActions>
    {modal ? (
      <FinePrint>
        <ThankYouCloseLink
          icon={null}
          className='thank-you-close-link'
          type='text'
          href={`${window.location.href}#`} // test throws an error w/o url
          onClick={onClose}>
          {getString(strings, 'thank-you-close-link')}
        </ThankYouCloseLink>
      </FinePrint>
    ) : null}
  </FormContainer>
);

ThankYou.propTypes = {
  getString: PropTypes.func,
  onClose: PropTypes.func,
  strings: PropTypes.shape({}),
  modal: PropTypes.bool,
};
ThankYou.defaultProps = {
  getString: () => {},
  onClose: () => {},
  strings: {},
  modal: false,
};

export default ThankYou;
