
/* eslint-disable max-len */
/**
 * @type {import('@builder.io/sdk/dist/src/builder.class').Component}
 */


export default {
  name: 'SignupWidgetV2',
  friendlyName: 'SignupWidgetV2',
  noWrap: true,
  defaultStyles: {
    display: 'inherit',
  },
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2Fc628c65d7d5440119b9777b21be9f89a?quality=60&width=200&height=200',
  inputs: [
    {
      name: 'leftColImage',
      friendlyName: 'Form Screen - Image',
      type: 'file',
      allowedFileTypes: ['jpg', 'jpeg', 'png', 'webp', 'svg'],
      defaultValue: '//images.ctfassets.net/ljtyf78xujn2/fEKJRhgJM90WTZt6RUnLo/d6f053455d2ab072f9c89497127a5f3e/Glowforge-Angle2-Pro-corner-cherry-073-2.png',
    },
    {
      name: 'brandBgImage',
      friendlyName: 'Interest Screen - Image',
      type: 'file',
      allowedFileTypes: ['jpg', 'jpeg', 'png', 'webp', 'svg'],
      defaultValue: '//images.ctfassets.net/ljtyf78xujn2/1WyhjUJC6B1j91DUnaFtzl/21413983346ff9cd2cec6ec75e1c7905/Card_BG.svg',
    },
    {
      name: 'thankYouImage',
      friendlyName: 'Final Screen - Image',
      type: 'file',
      allowedFileTypes: ['jpg', 'jpeg', 'png', 'webp', 'svg'],
      defaultValue: '//images.ctfassets.net/ljtyf78xujn2/4U7Fe73owMa6yuKm0UyqmI/92bbd608e37f0d55791c8acee3944628/Bailey_S.jpg',
    },
    {
      type: 'object',
      name: 'textStrings',
      defaultValue: {
        'signup-modal-title': 'Dear Glowforge,',
        'signup-modal-name-label': 'My name is',
        'signup-modal-email-label': 'My email is',
        'signup-modal-phone-label': 'My phone is',
        'signup-modal-submit-label': 'Send me free stuff',
        'signup-modal-fine-print': '(No Spam! Only good stuff, we promise!)',
        'signup-modal-thank-you-body':
          "Hey there, it's Bailey and I've been at Glowforge since its very beginning! \n\n__Have a project in mind that might just work? Let's put our heads together and figure out how to make it happen.__\n\nWhen it comes to creating what you’ve always dreamed about, you’re the expert. When it comes to choosing the exact right Glowforge to get the job done, you can talk to ours.\n\n<style>\n  .gf-styled .signup-form-wrapper {\n    margin: 64px auto;\n    display: flex;\n    justify-content: center;\n  }\n</style>",
        'signup-modal-thank-you-redirect-label': 'Speak with an Expert',
        'signup-modal-thank-you-redirect-url':
          'https://explore.glowforge.com/meetings/glowforge/qa-with-a-glowforge-expert',
        'signup-modal-interests-header':
          'What are you most interested in learning to do?',
        'signup-modal-interests-label-1': 'Design new projects',
        'signup-modal-interests-label-2': 'Craft with new materials',
        'signup-modal-interests-label-3': 'Make unique art',
        'signup-modal-interests-label-4': 'Personalize my gifts',
        'signup-modal-interests-label-5': 'Sell my projects',
        'signup-modal-interests-label-6': 'Teach students STEAM',
        'signup-modal-not-sure-label': 'I\'m not sure',
      },
      subFields: [
        { type: 'string', name: 'signup-modal-title' },
        { type: 'string', name: 'signup-modal-name-label' },
        { type: 'string', name: 'signup-modal-email-label' },
        { type: 'string', name: 'signup-modal-phone-label' },
        { type: 'string', name: 'signup-modal-submit-label' },
        { type: 'string', name: 'signup-modal-fine-print' },
        { type: 'string', name: 'signup-modal-thank-you-body' },
        { type: 'string', name: 'signup-modal-thank-you-redirect-label' },
        { type: 'string', name: 'signup-modal-thank-you-redirect-url' },
        { type: 'string', name: 'signup-modal-interests-header' },
        { type: 'string', name: 'signup-modal-interests-label-1' },
        { type: 'string', name: 'signup-modal-interests-label-2' },
        { type: 'string', name: 'signup-modal-interests-label-3' },
        { type: 'string', name: 'signup-modal-interests-label-4' },
        { type: 'string', name: 'signup-modal-interests-label-5' },
        { type: 'string', name: 'signup-modal-interests-label-6' },
        { type: 'string', name: 'signup-modal-not-sure-label' },
      ],
    },
  ],
};
