import config from './signup-widget.builder.config';

/* eslint-disable max-len */
/**
 * @type {import('@builder.io/sdk/dist/src/builder.class').Component}
 */
export default {
  name: 'SignUpModalV2',
  friendlyName: 'Sign Up ModalV2',
  noWrap: true,
  defaultStyles: {
    display: 'inherit',
  },
  image:
    'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F31f3603db9244f22999bcc88724a5b23?quality=60&width=200&height=200',
  inputs: [
    {
      name: 'openDelay',
      friendlyName: 'Modal Open Delay (ms)',
      type: 'number',
      defaultValue: 10000,
    },
    {
      name: 'showAgainAfterDays',
      friendlyName: 'Days Before Showing Modal Again',
      type: 'number',
      defaultValue: 3,
    },
    ...config.inputs,
  ],
};
