import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormContainer, H2 } from '../shared.styles';
import { 
  InterestGrid,
  InterestButton,
} from './interests.styles'

/**
 * Find array of strings with a even more specific prefix in Contentful data
 * @param {{ [key: string]: string }} strings
 * @param { string } string
 */
const getRelatedStrings = (strings, string) =>
  Object.keys(strings).filter((key) => key.includes(`signup-modal-${string}`));

const Interests = ({
  getString,
  nextFormStep,
  onSubmit,
  setFormStep,
  strings,
}) => {
  const interestsLabels = getRelatedStrings(strings, 'interests-label');

  /**
   * Submits interest and contact info
   */
  const onSelectInterest = useCallback(
    (e, interest) => {
      setFormStep(nextFormStep);

      onSubmit(interest);
    },
    [setFormStep, nextFormStep, onSubmit]
  );

  return (
    <FormContainer className='interests-step'>
      <H2 style={{maxWidth: '650px'}}>
        {getString(strings, 'interests-header')}
      </H2>
      <InterestGrid>
        {interestsLabels.map((label) => (
          <InterestButton
            variant='ghost-blue'
            key={`${label}-button`}
            onClick={(e) => onSelectInterest(e, strings[label])}>
            {strings[label]}
          </InterestButton>
        ))}

        <InterestButton 
          variant='ghost-blue'
          className='not-sure-btn'
          onClick={onSelectInterest}>
          {getString(strings, 'not-sure-label')}
        </InterestButton>
      </InterestGrid>
    </FormContainer>
  );
};

Interests.propTypes = {
  getString: PropTypes.func,
  nextFormStep: PropTypes.string,
  setFormStep: PropTypes.func,
  strings: PropTypes.shape({}),
  onSubmit: PropTypes.func,
};
Interests.defaultProps = {
  getString: () => {},
  nextFormStep: () => {},
  onSubmit: () => {},
  setFormStep: () => {},
  strings: {},
};

export default Interests;
