import styled from 'styled-components';

import { FinePrint } from '../shared.styles'

export const FormRow = styled('div')`
  display:block;
  width: 100%;
`

export const Label = styled('label')`
  display: inline-block;
  font-size: 1.7rem;
  font-family: 'Exo 2', sans-serif;
  line-height: 1.8rem;
  margin-right: 10px;
`

export const Required = styled('span')`
  color: red;
`

export const Input = styled('input')`
  display: inline-block;
  margin-bottom: 1rem;
  font-family: 'Exo 2', sans-serif;
  font-size: 1.5rem;
  line-height: 1.8rem;

  &::placeholder {
    color: #c8cbd0;
  }

  &[type='text'],
  &[type='password'],
  &[type='number'],
  &[type='email'],
  &[type='tel'] {
    padding: 0.5rem 0;
    border-top: 0;
    border-bottom: 3px solid black;
    border-right: 0;
    border-left: 0;
    transition: border-bottom 0.3s ease-in-out;
    background-color: transparent;

    &:focus {
      border-bottom: 3px solid #00c0d4;
    }

    &:disabled {
      background-color: #c8cbd0;
      border-bottom: 3px solid #334b4f;
    }
  }
`

export const FormErrors = styled('div')`
  width: 100%;
  text-align: center;
  color: #b53c23;
  margin-bottom: 1rem;
`

export const FormFinePrint = styled(FinePrint)`
  margin-top: 10px;
  margin-left: 10px;
`