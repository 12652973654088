import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
/* eslint "react/prefer-stateless-function": 0 */
// Cannot be a stateless function because the DragDropContext needs an element ref

import VerticalScrollBoxStyles from './vertical-scroll-box.styles';

/*
  On render, will recalculate scroll indicators if the children length changes or if the
  optional updateKey property is not equal to the previous value.
 */
export default class VerticalScrollBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.updateScrollIndicators = this.updateScrollIndicators.bind(this);
    this.scrollDidMount = this.scrollDidMount.bind(this);
    this.scrollBy = this.scrollBy.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.outerElementDidMount = this.outerElementDidMount.bind(this);
    this.scrollContentDidMount = this.scrollContentDidMount.bind(this);
    this.sizeContent = this.sizeContent.bind(this);
    this.sizeContentOnBodyAnimationEnd = this.sizeContentOnBodyAnimationEnd.bind(this);
  }

  componentDidUpdate(oldProps) {
    const { children, updateKey } = this.props;
    if (oldProps.children.length !== children.length
      || oldProps.updateKey !== updateKey
    ) {
      this.sizeContent();
    } else {
      this.updateScrollIndicators();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.sizeContent);
    document.body.removeEventListener('animationend', this.sizeContentOnBodyAnimationEnd);
    document.body.removeEventListener('transitionend', this.sizeContentOnBodyAnimationEnd);
  }

  updateScrollIndicators() {
    this.setState({
      contentUp: this.element.scrollTop > 0,
      contentDown: this.element.scrollTop < this.element.scrollHeight - this.element.offsetHeight,
    });
  }

  scrollDidMount(scroll) {
    const { onScroll } = this.props;
    if (scroll) {
      this.element = scroll;
      scroll.addEventListener('scroll', this.updateScrollIndicators);
      if (onScroll) {
        scroll.addEventListener('scroll', onScroll);
      }
      this.updateScrollIndicators();
      window.addEventListener('resize', this.sizeContent);
    }
  }

  scrollBy(offsetX, offsetY) {
    this.scrollTo(this.element.scrollTop + offsetY, this.element.scrollLeft + offsetX);
  }

  scrollTo(x, y) {
    this.element.scrollTop = x;
    this.element.scrollLeft = y;
    this.updateScrollIndicators();
  }

  sizeContentOnBodyAnimationEnd(e) {
    if (e.target && e.target.contains && e.target.contains(this.outerElement)) {
      this.sizeContent();
    }
  }

  outerElementDidMount(element) {
    this.outerElement = element;
    this.sizeContent();
    document.body.addEventListener('animationend', this.sizeContentOnBodyAnimationEnd);
    document.body.addEventListener('transitionend', this.sizeContentOnBodyAnimationEnd);
  }

  scrollContentDidMount(element) {
    this.contentElement = element;
    this.sizeContent();
  }

  sizeContent() {
    if (!this.outerElement || !this.contentElement) {
      return;
    }

    this.contentElement.style.width = `${this.outerElement.offsetWidth}px`;
    this.outerElement.style.height = ''; // allow it to resize based on the css
    this.outerElement.style.height = `${this.outerElement.offsetHeight}px`;
    this.updateScrollIndicators();
  }

  render() {
    const {
      children,
      className,
    } = this.props;
    const {
      contentUp,
      contentDown,
    } = this.state;

    const classes = classNames('ScrollBox', className, {
      contentUp,
      contentDown,
    });

    return (
      <VerticalScrollBoxStyles ref={this.outerElementDidMount} className={classes}>
        <div ref={this.scrollDidMount} className='verticalScrollBox'>
          <div ref={this.scrollContentDidMount} className='scroll-box-content'>
            {children}
          </div>
        </div>
      </VerticalScrollBoxStyles>
    );
  }
}

VerticalScrollBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onScroll: PropTypes.func,
  updateKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.node,
  ]),
};

VerticalScrollBox.defaultProps = {
  className: null,
  onScroll: null,
  updateKey: null,
};
