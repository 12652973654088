import styled from 'styled-components'

export default styled('div')`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .verticalScrollBox {
    width: ~"calc(100% + 100px)";
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: content-box;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.contentUp::before {
    pointer-events: none;
    background: radial-gradient(ellipse farthest-corner at 50% 0%, rgba(50, 50, 50, 0.3) 0%, transparent 65%);
    content: ' ';
    height: 5px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &.contentDown::after {
    pointer-events: none;
    background: radial-gradient(ellipse farthest-corner at 50% 100%, rgba(50, 50, 50, 0.5) 0%, transparent 65%);
    bottom: 0;
    content: ' ';
    height: 6px;
    position: absolute;
    width: 100%;
  }
`