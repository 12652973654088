import styled from 'styled-components';
import Link from '@glowforge/gf-styled/src/components/link'

import { 
  ModalActions
} from '../shared.styles';

export const ThankYouBody = styled('div')`
  max-width: 650px;
  margin-bottom: 2rem;

  .markdown p {
    font-size: 16px;
    font-family: 'Exo 2', sans-serif;
    line-height: 1.3rem;
  }
`

export const ThankYouModalActions = styled(ModalActions)`
  justify-content: center;
`

export const ThankYouCloseLink = styled(Link)`
  display: inline-block;
  white-space: normal;
  cursor: pointer;
  box-shadow: none;
`