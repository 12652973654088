import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';
import styled, { createGlobalStyle } from 'styled-components';
import VerticalScrollBox from '../vertical-scroll-box';
import SVG from '../svg';
import { getReactRoot } from '../../util';

const StyledReactModal = styled(ReactModal)`
position: absolute;
left: 0;
right: 0;
max-width: 100%;
max-height: 100%;
display: flex;
flex-direction: column;
background-color: white;
padding: 1rem;
border-radius: 4px;
border: 1px solid #f0f0f0;
top: 50%;
transform: translateY(-50%);

// if no scrollbox, make sure overflow is scrollable.
&:not(.withScrollBox) {
  overflow-y: auto;
  overflow-x: hidden;
}

&.withPadding {
  left: 0.5rem;
  right: 0.5rem;
}

@media (min-width: 770px) {
  max-width: 80vw;
  max-height: 80vh;
  margin: auto;
  top: 10vh;
  transform: none;
  padding: 2rem 2rem 1rem;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;
}

.content > * {
  margin: 0;
}
`

// We're using Global style here for the overlay which can't target otherwise.
const DialogStyle = createGlobalStyle`
.dialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(51, 75, 79, 0.25);
  z-index: 10000;
}
`

const TopRightCloseButton = styled('button')`
  display: block;
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  text-align: center;
  // Make the hit target large
  width: 40px;
  height: 40px;
  padding: 10px;
  z-index: 1; // make it above other content, relative
  :hover {
    cursor: pointer;
  }
`


/**
 * A Modal/Dialog component.  Uses react-modal for the modal implementation.
 * This modal is controlled, so you must implement the logic for whether the modal is
 * open/closed in a wrapping component.  react-modal handles close logic and calls
 * onRequestClose when it determines the modal should close.
 *
 * This component was copied over from ecomm-front-end.
 * Conceptually, a Dialog differs from a Modal in that a Dialog has buttons.
 * Practically, our ui-common Modal implementation is very minimal and used in a few apps.
 * We should move towards this component as it has a more accessible modal implementation.
 *
 * To use this component, you must pass in a `reactRoot` prop or define `reactRoot` on the
 * window. See `../../util/react-root.jsx` for info on defining it.
 *
 * @param {Object} props - React props
 * @param {String} props.overlayClassName - Class for the overlay underneath the modal.
 * @param {String} props.className - Class applied to Modal component
 * @param {String} props.title - Title applied to top of Modal.
 * @param {Boolean} props.isOpen - Controls whether the Modal isOpen)
 * @param {Node} props.actionButtons - React node to render a row of action buttons at bottom
 * @param {Function} props.onRequestClose - Callback when react-modal decides the modal should close
 * @param {Boolean} props.withScrollBox - Includes the content inside a vertically scrolling box.
 * @param {Boolean} props.showTopRightCloseButton - Show an 'x' close button on top right.
 * @param {Boolean} props.withPadding - Give left and right padding to dialog content.
 * @param {String} props.reactRoot - This is a query selector for the id of the consuming app's
 *                                   React root element. ie '#root' or '#react'
 */
export class Dialog extends React.Component {
  constructor(props) {
    super(props);
    if (typeof window !== 'undefined') {
      const reactRoot = this.getReactRoot();
      // React Modal requires us to register the React root
      ReactModal.setAppElement(document.querySelector(reactRoot));
    }
  }

  // reactRoot passed as prop takes precedence over window
  getReactRoot() {
    const { reactRoot } = this.props;
    return reactRoot || getReactRoot();
  }

  render() {
    const {
      overlayClassName,
      className,
      children,
      title,
      isOpen,
      actionButtons,
      onRequestClose,
      withScrollBox,
      showTopRightCloseButton,
      withPadding,
    } = this.props;

    const content = withScrollBox ? (
      <VerticalScrollBox updateKey={children}>
        { children }
      </VerticalScrollBox>
    ) : children;
    const header = title ? <h3 className='h3'>{title}</h3> : null;
    const topRightCloseButton = showTopRightCloseButton ? (
      <TopRightCloseButton onClick={onRequestClose}>
        <SVG className='dynamic clearSvg' id='close' width={20} height={20} />
      </TopRightCloseButton>
    ) : null;

    return (
      <>
        <DialogStyle/>
        <StyledReactModal
          className={classnames('Dialog', className, { withPadding, withScrollBox })}
          overlayClassName={classnames('dialogOverlay', overlayClassName)}
          contentLabel={title}
          isOpen={isOpen}
          onRequestClose={onRequestClose}>
          {topRightCloseButton}
          {header}
          {content}
          <div className='actionButtons'>{actionButtons}</div>
        </StyledReactModal>
      </>
    );
  }
}

Dialog.propTypes = {
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onRequestClose: PropTypes.func,
  withScrollBox: PropTypes.bool,
  showTopRightCloseButton: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  actionButtons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  withPadding: PropTypes.bool,
  reactRoot: PropTypes.string,
};

Dialog.defaultProps = {
  className: '',
  overlayClassName: '',
  isOpen: false,
  title: '',
  onRequestClose: null,
  withScrollBox: true,
  showTopRightCloseButton: true,
  actionButtons: null,
  withPadding: false,
  reactRoot: null,
};

export default Dialog;
