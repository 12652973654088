import styled from 'styled-components';
import Button from '@glowforge/gf-styled/src/components/button/button';

export const InterestGrid = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  max-width: 650px;
`

export const InterestButton = styled(Button)`
  flex-grow: 1;
  justify-content: center;
  overflow: auto;
  margin: 0px 8px 15px 8px;

  span {
    width: 100%;
  }
`