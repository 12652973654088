import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useLocalStorage from '@glowforge/builder-components/lib/util/use-local-storage';
import {
  trackFormSubmit,
  trackFormDismiss,
  trackFormStart,
  trackFormView,
} from '@glowforge/gf-styled/src/util/form-tracking';
import { grabUtmParams } from '../../util/analytics';
import ShareProgramContext from '../../context/ShareProgramContext';
import LocationContext from '../../context/LocationContext';

import {
  SignupWidgetContainer,
  Wrapper,
  GreenBackground,
  CircleImage,
  FormWrapper,
  CloseX,
  CloseButton,
} from './shared.styles';

import FormStep from './steps/form';
import InterestsStep from './steps/interests';
import ThankYouStep from './steps/thank-you';

/**
 * Storage key to persist state
 * Even though this isn't just the modal anymore,
 * keeping key name the same so users don't still see it
 * who have already completed forms before this change.
 */
const SUBMITTED_KEY = '_gf_signup_modal_submitted';

/**
 * Used to control when the user sees things like validation error messages
 */
const formStates = {
  READY: 'READY',
  CONTACT_COMPLETE: 'CONTACT_COMPLETE',
  SUBMITTED: 'SUBMITTED',
};

/**
 * Form steps control which screen the user sees
 */
const formSteps = {
  FORM: 'FORM',
  INTERESTS: 'INTERESTS',
  THANK_YOU: 'THANK_YOU',
};

/**
 * Get referral and promo link data out of referralInfo context object
 * @param {object} referralInfo ReferralInfo context
 */
const getAttributions = (referralInfo = {}, countryCode = '', region = '') => {
  const { code = '', pl: { id: promoLink = '' } = {} } = referralInfo;
  return {
    referral_code: code,
    promo_link: promoLink,
    country_code: countryCode,
    region,
  };
};

/**
 * Find string with a specific prefix in Contentful data
 * @param {{ [key: string]: string }} strings
 * @param {string} string
 */
const getString = (strings, string) => strings[`signup-modal-${string}`] || '';

/**
 * Shows a modal that accepts name, email and phone number followed by a thank
 * you message after submission
 */
const SignupWidget = ({
  onClose,
  className,
  textStrings,
  leftColImage,
  brandBgImage,
  thankYouImage,
  attributes,
  modal,
}) => {
  const strings = textStrings;
  const formContainer = useRef(null);

  const [submitted, setSubmitted] = useLocalStorage(SUBMITTED_KEY, false);

  const [formValues, setFormValues] = useState({});
  const [formStep, setFormStep] = useState(formSteps.FORM);
  const [formState, setFormState] = useState(formStates.READY);
  const { referralInfo = {} } =
    useContext(ShareProgramContext);
  const { country: countryCode, region } = useContext(LocationContext);

  const formProperties = {
    form_id: 'signup-widget',
    form_name: 'Madlib',
    form_service: 'builder',
    delivery_method: modal ? 'popup' : 'embed',
  };

  const formData = {
    ...formProperties,
    ...{
      ...formValues,
      email: formValues.email || '',
      phone: formValues.phone || '',
    },
  };

  /**
   * Server side rendering issue solution
   * If you try to set these outside of the useEffect that's watching submitted
   * then the server will believe the form step is .FORM because it won't have
   * access to the value in localstorage to check.
   */
  useEffect(() => {
    setFormState(submitted ? formStates.SUBMITTED : formStates.READY);
    setFormStep(submitted ? formSteps.THANK_YOU : formSteps.FORM);
  }, [submitted]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      trackFormView(formContainer.current, {}, formProperties);
      trackFormStart(formContainer.current, {}, formProperties);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    trackFormDismiss(
      {},
      {
        ...formData,
        url: window.location.href,
        ...getAttributions(referralInfo, countryCode, region),
      }
    );
    onClose();
  };

  const onSubmitEmail = () => {
    const utmParams = grabUtmParams();
    const { code = '' } = referralInfo;

    if (window.analytics) {
      window.analytics.identify(formValues);
    }

    const segmentData = {
      ...formData,
      url: window.location.href,
      ...getAttributions(referralInfo, countryCode, region),
      ...utmParams,
    };

    const datalayerData = {
      ...formData,
      gf_referral_code: code,
      ...utmParams,
    };

    trackFormSubmit({ segmentEvent: 'Form Completed' }, segmentData);
    trackFormSubmit({ datalayerEvent: 'Form Completed' }, datalayerData);
  };

  /**
   * Collect form response and transition to thank you page
   * @param {FormEvent<HTMLFormElement>} e
   */
  const onSubmitInterest = (interest) => {
    setFormState(formStates.SUBMITTED);

    const filteredInterest =
      interest || getString(strings, 'not-sure-label') || '';

    const utmParams = grabUtmParams();
    const { code = '' } = referralInfo;

    const formDataWithInterest = {
      ...formData,
      interest: filteredInterest,
      ...utmParams,
    };

    const segmentData = {
      ...formDataWithInterest,
      url: window.location.href,
      ...getAttributions(referralInfo, countryCode, region),
    };

    const datalayerData = {
      ...formDataWithInterest,
      gf_referral_code: code,
    };

    if (window.analytics) {
      window.analytics.identify({ ...formValues, interest: filteredInterest });
    }

    trackFormSubmit({ segmentEvent: 'Survey Completed' }, segmentData);
    trackFormSubmit({ datalayerEvent: 'Survey Completed' }, datalayerData);

    setSubmitted(true);
  };

  let currentStep = null;
  let stepImage = null;

  switch (formStep) {
    case formSteps.FORM:
      currentStep = (
        <FormStep
          key='info-step-widget'
          getString={getString}
          formState={formState}
          formValues={formValues}
          nextFormState={formStates.CONTACT_COMPLETE}
          nextFormStep={formSteps.INTERESTS}
          setFormState={setFormState}
          setFormStep={setFormStep}
          setFormValues={setFormValues}
          strings={strings}
          onSubmit={onSubmitEmail}
          referralInfo={referralInfo}
        />
      );
      stepImage = leftColImage;
      break;
    case formSteps.INTERESTS:
      currentStep = (
        <InterestsStep
          key='interest-step-widget'
          getString={getString}
          nextFormStep={formSteps.THANK_YOU}
          onSubmit={onSubmitInterest}
          setFormStep={setFormStep}
          strings={strings}
        />
      );
      stepImage = brandBgImage;
      break;
    case formSteps.THANK_YOU:
      currentStep = (
        <ThankYouStep
          key='thank-you-step-widget'
          getString={getString}
          onClose={onClose}
          strings={strings}
          modal={modal}
        />
      );
      stepImage = thankYouImage;
      break;
    default:
      currentStep = null;
      stepImage = null;
  }

  return (
    <SignupWidgetContainer
      {...attributes}
      className={`SignupWidget ${className} ${attributes.className}`}>
      <Wrapper>
        <GreenBackground>
          <CircleImage src={stepImage} modal />
        </GreenBackground>
        <FormWrapper ref={formContainer}>
          {modal ? (
            <CloseButton type='button' onClick={handleCloseModal}>
              <CloseX />
            </CloseButton>
          ) : null}
          {currentStep}
        </FormWrapper>
      </Wrapper>
    </SignupWidgetContainer>
  );
};

SignupWidget.propTypes = {
  attributes: PropTypes.shape({
    className: PropTypes.string,
  }),
  className: PropTypes.string,
  onClose: PropTypes.func,
  textStrings: PropTypes.shape({}),
  leftColImage: PropTypes.string,
  rightColImage: PropTypes.string,
  brandBgImage: PropTypes.string,
  thankYouImage: PropTypes.string,
  modal: PropTypes.bool,
};
SignupWidget.defaultProps = {
  attributes: {},
  className: '',
  onClose: () => {},
  textStrings: {},
  leftColImage: '',
  rightColImage: '',
  brandBgImage: '',
  thankYouImage: '',
  modal: false,
};

export default SignupWidget;
