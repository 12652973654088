import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Builder } from '@builder.io/react';
import styled, { createGlobalStyle } from 'styled-components';
import Dialog from '@glowforge/gf-styled/src/components/dialog';
import useLocalStorage from '@glowforge/builder-components/lib/util/use-local-storage';
import ModalPriorityContext from '../../context/ModalPriorityContext/ModalPriorityContext';

import SignupWidget from './signup-widget';

/**
 * Unique ID for this modal
 */
const MODAL_ID = 'signup';

/**
 * Max number of times a user will see the modal
 */
const SHOW_MAX_TIMES = 3;

/**
 * How many days to wait before showing the modal again
 */
const MS_PER_DAY = 86400000;

/**
 * Storage keys used to persist state
 */
const DISMISSED_AT_KEY = '_gf_signup_modal_dismissed_at';
const DISMISSED_COUNT_KEY = '_gf_signup_modal_dismissed_count';
const SUBMITTED_KEY = '_gf_signup_modal_submitted';

/**
 * Global styles used here since we can't quite apply it in another way.
 */
const SignUpModalStyles = createGlobalStyle`
  .Signup.SignupModal {
    @media ${(props) => props.theme.queries.desktopAndUp} {
      background-color: transparent !important;
    }
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    outline-style: none !important;
    box-shadow: none !important;
    outline-color: transparent !important;
    border:none !important;
    border-color: transparent !important;
  }
  .SignupModalOverlay {
    background-color: rgba(255, 255, 255, 0.8);
    overflow-y: scroll;
  }
`;

/**
 * Shows a modal that accepts name, email and phone number followed by a thank
 * you message after submission.
 *
 * TODO: Should extend this to make configurable.
 */
const SignupModal = ({
  className,
  openDelay,
  showAgainAfterDays,
  ...props }) => {
  const [submitted] = useLocalStorage(SUBMITTED_KEY, false);
  const [dismissedAt, setDismissedAt] = useLocalStorage(DISMISSED_AT_KEY, 0);
  const [dismissedCount, setDismissedCount] = useLocalStorage(
    DISMISSED_COUNT_KEY,
    0
  );
  const { isModalOpen, openModal, closeModal } =
    useContext(ModalPriorityContext);

  /**
   * When component first renders, fetch referral info and then show the dialog
   * if the user hasn't seen it for X days
   */
  useEffect(() => {
    const daysSinceDismissed = (Date.now() - dismissedAt) / MS_PER_DAY;
    if (
      submitted ||
      daysSinceDismissed < showAgainAfterDays ||
      dismissedCount >= SHOW_MAX_TIMES
    ) {
      return () => {};
    }
    const timer = setTimeout(() => openModal(MODAL_ID), openDelay);
    return () => {
      clearTimeout(timer);
      closeModal(MODAL_ID);
    };
    // eslint-disable-next-line
  }, []);

  /**
   * Close modal and track so we can decide when to show it again in the future
   */
  const close = useCallback(() => {
    setDismissedCount(dismissedCount + 1);
    setDismissedAt(Date.now());
    closeModal(MODAL_ID);
  }, [setDismissedCount, dismissedCount, setDismissedAt, closeModal]);

  /**
   * Handle click on the close button
   */
  const onClose = useCallback(() => {
    close();
  }, [close]);

  return (
    <>
      {Builder.isEditing ? (
        <p>Signup Modal (visible while editing)</p>
      ) : null}
      <SignUpModalStyles />
      <Dialog
        className={`Signup SignupModal ${className}`}
        overlayClassName='SignupModalOverlay'
        withScrollBox={false}
        onRequestClose={onClose}
        showTopRightCloseButton={false}
        isOpen={isModalOpen(MODAL_ID)}>
        <SignupWidget {...props} onClose={onClose} modal/>
      </Dialog>
    </>
  );
};

SignupModal.propTypes = {
  className: PropTypes.string,
  openDelay: PropTypes.number,
  showAgainAfterDays: PropTypes.number,
};
SignupModal.defaultProps = {
  className: '',
  openDelay: 10000,
  showAgainAfterDays: 3,
};

export default styled(SignupModal)`
  padding: 0;

  @media (max-width: 960px) {
    top: 0;
    margin: 1rem;
    transform: none;
  }

  max-width: 60rem !important;
  margin-bottom: 2rem !important;
  border: 0 !important;
  overflow: visible !important;
  position: relative !important;
  max-height: none !important;

  .actionButtons {
    margin-top: 0;
  }

  .SignupWidget {
    width: 100% !important;
    box-shadow: none;
  }
`;
