import React from 'react';
import styled from 'styled-components';
import {
  Heading,
} from '@glowforge/gf-styled/src/components/typography';

/**
 * Note, Doing Desktop pass first.  Mobile is coming in the new few commits 
 */

export const SignupWidgetContainer = styled('div')`
  margin: 0 auto;
  position: relative;
`

// Wrapper
export const Wrapper = styled('div')`
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    /* Desktop */
    width: 800px;
    max-width: 100%;
    min-height: 400px;
  }
`

// Green Background
export const GreenBackground = styled('div')`
  position: relative;
  overflow: hidden; /* used to clip the CircleImage */

  @media ${(props) => props.theme.queries.desktopAndUp} {
    /* Desktop */
    position: absolute;
    right: 110px;
    left: 0;
    top: 0;
    bottom: 0;

    background-color: ${(props) => props.theme.colors.magic.base};
    background-image: url('../source_to_dotted_bg');
    background-position: left top;
    background-repeat: repeat-y;
  }
`

// Clip path'd background image div using a prop for background source
export const CircleImage = styled('div')`
  position: relative;
  display:none;
  background-image: url('${(props) => props.src}');
  background-size: cover;
  background-position: center;
  
  aspect-ratio: 1/1;
  ${(props) => props.modal ? `
    width: 100%;
  `:`
    border-radius: 50%;
    width: 130%;
    left: -140px;
    top: -20px;
  `}

  /* Desktop */
  @media ${(props) => props.theme.queries.desktopAndUp} {
    display: block;
    border-radius: 50%;
    position: absolute;
    width: 330px;
    height: 330px;
    left: -85px;
    bottom: -25px;
  }
`

export const FormWrapper = styled('div')`
  position: relative;
  padding: 20px;

  @media ${(props) => props.theme.queries.desktopAndUp} {
    /* Desktop */
    background-color: white;
    box-shadow: 0px 10px 15px #00000033;
    padding: 25px 28px;
    border-radius: 10px;
    margin-top: 70px;
    margin-bottom: 48px;
    margin-left: 210px;
  }
`

const X = () => (
<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
  <g id='CloseX' transform='translate(-852.009 -205.247)'>
    {/* eslint-disable-next-line max-len */}
    <path id='close' d='M15.968.847a.576.576,0,0,0-.152-.56.862.862,0,0,0-.507-.305.571.571,0,0,0-.558.153c-.051.051-.1.1-.152.1L8.007,6.954,1.363.287A.739.739,0,0,0,.3.134.745.745,0,0,0,.146,1.2c.051.051.1.1.1.153L6.891,8.023.247,14.69A.789.789,0,0,0,.3,15.759a.65.65,0,0,0,.507.2,1.061,1.061,0,0,0,.558-.2L8.007,9.092l6.643,6.667a.761.761,0,0,0,.558.2.813.813,0,0,0,.558-.254.767.767,0,0,0,.2-.56.906.906,0,0,0-.2-.509L9.071,8.023l6.593-6.667A.866.866,0,0,0,15.968.847Z' transform='translate(852.021 205.284)' fill='#27b8cf'/>
  </g>
</svg>
)

export const CloseX = styled(X)`
  cursor: pointer;
`

export const CloseButton = styled('button')`
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: transparent;
  border: none;
  @media ${(props) => props.theme.queries.desktopAndUp} {
    top: 10px;
    right: 10px;
  }
`

export const FinePrint = styled('p')`
  width: 100%;
  text-align: left;
  font-family: 'Exo 2', sans-serif !important;
  font-size: 0.87rem !important;
  line-height: 1.1rem !important;
  color: ${(props) => props.theme.colors.magic.base} !important;
`

export const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const H2 = styled(Heading)`
  margin-bottom: 20px;
`

export const Form = styled('form')`
  margin-bottom: 1rem;
`

export const ModalActions = styled('div')`
  display: flex;
  justify-content: left;
  margin-bottom: 1rem;
  flex-direction: column;
  @media ${(props) => props.theme.queries.desktopAndUp} {
    flex-direction: row;
  }
`