import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@glowforge/gf-styled/src/components/button';

import {
  FormContainer,
  H2,
  Form,
  ModalActions,
} from '../shared.styles'

import {
  FormRow,
  Label,
  Required,
  Input,
  FormErrors,
  FormFinePrint,
} from './form.styles'

/**
 * Format input as a user types
 */
const inputFormatters = {
  /**
   * Transforms "1234567891" to "(123) 456-7891". Only supporting US phone
   * numbers for now
   */
  phone(prev = '', curr = '') {
    if (curr.length > prev.length) {
      const nums = (curr.match(/(\d+)/g) || []).join('');
      let output = '';
      for (let i = 0; i < Math.min(nums.length, 10); i += 1) {
        if (i === 0) output += '(';
        if (i === 3) output += ') ';
        if (i === 6) output += '-';
        output += nums[i];
      }
      return output;
    }
    return curr;
  },
};

/**
 * Validate form input
 * @param {{ [key: string]: string }} values Form values
 */
const validate = (values) => {
  const validationErrors = {};

  if (!values.name) validationErrors.name = 'Name is required';
  if (!values.email) validationErrors.email = 'Email is required';
  if (values.email && !values.email.match(/[^@]+@[^.]+\..+/)) {
    validationErrors.email = 'Email address is invalid';
  }
  if (values.phone && !values.phone.match(/^\(\d{3}\)\s\d{3}-\d{4}$/)) {
    validationErrors.phone = 'Phone number is invalid';
  }

  return validationErrors;
};

const ContactForm = ({
  getString,
  formState,
  formValues,
  nextFormState,
  nextFormStep,
  setFormState,
  setFormStep,
  setFormValues,
  strings,
  onSubmit,
}) => {
  const [formErrors, setFormErrors] = useState({});
  const [isPhoneVisible, setIsPhoneVisible] = useState(false);
  const hasErrors = Object.keys(formErrors).length > 0;

  /**
   * Validate input when values change, and show phone number after first inputs
   * are filled
   */
  useEffect(() => {
    if (formValues.name && formValues.email && !isPhoneVisible) {
      setIsPhoneVisible(true);
    }
    setFormErrors(validate(formValues));
    // eslint-disable-next-line
  }, [formValues]);

  /**
   * Update form state when inputs change
   * @param {ChangeEvent<HTMLInputElement>} e
   */
  const onChange = useCallback(
    (e) => {
      // eslint-disable-next-line prefer-const
      let { name, value } = e.target;

      if (inputFormatters[name]) {
        value = inputFormatters[name](formValues[name], value);
      }

      setFormValues({
        ...formValues,
        [name]: value,
      });
      // eslint-disable-next-line
    },
    [formValues, setFormValues]
  );

  /**
   * Handle moving to next form screen
   */
  const onFormComplete = useCallback(
    (e) => {
      e.preventDefault();

      setFormState(nextFormState);

      if (hasErrors) return;

      onSubmit(formValues);
      setFormStep(nextFormStep);
      // eslint-disable-next-line
    },
    [
      setFormState,
      nextFormState,
      hasErrors,
      onSubmit,
      formValues,
      setFormStep,
      nextFormStep,
    ]
  );

  return (
    <FormContainer className='info-step'>
      <H2>
        {getString(strings, 'title')}
      </H2>
      <Form onSubmit={onFormComplete}>
        <FormRow>
          <Label htmlFor='signup-modal-name' className='required'>
            {getString(strings, 'name-label')}<Required>*</Required>
          </Label>
          <Input
            className='clearInput'
            id='signup-modal-name'
            name='name'
            type='text'
            placeholder='name'
            value={formValues.name || ''}
            onChange={onChange}
          />
        </FormRow>
        <FormRow>
          <Label htmlFor='signup-modal-email' className='required'>
            {getString(strings, 'email-label')}<Required>*</Required>
          </Label>
          <Input
            className='clearInput'
            id='signup-modal-email'
            name='email'
            type='email'
            placeholder='email address'
            value={formValues.email || ''}
            onChange={onChange}
          />
        </FormRow>
        {isPhoneVisible && (
          <FormRow>
            <Label htmlFor='signup-modal-phone'>
              {getString(strings, 'phone-label')}
            </Label>
            <Input
              className='clearInput'
              id='signup-modal-phone'
              name='phone'
              type='tel'
              placeholder='phone number'
              value={formValues.phone || ''}
              onChange={onChange}
            />
          </FormRow>
        )}
        {hasErrors && formState === nextFormState && (
          <FormErrors>
            {Object.keys(formErrors)
              .map((key) => formErrors[key])
              .join(', ')}
          </FormErrors>
        )}
        <ModalActions>
          <Button isSubmit type='default' size='large'>
            {getString(strings, 'submit-label')}
          </Button>
          <FormFinePrint>
            {getString(strings, 'fine-print')}
          </FormFinePrint>
        </ModalActions>
      </Form>
    </FormContainer>
  );
};

ContactForm.propTypes = {
  formState: PropTypes.string,
  formValues: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  getString: PropTypes.func,
  nextFormState: PropTypes.string,
  nextFormStep: PropTypes.string,
  onSubmit: PropTypes.func,
  setFormState: PropTypes.func,
  setFormStep: PropTypes.func,
  setFormValues: PropTypes.func,
  strings: PropTypes.shape({}),
};
ContactForm.defaultProps = {
  formState: "",
  formValues: {},
  getString: () => {},
  nextFormState: '',
  nextFormStep: '',
  onSubmit: () => {},
  setFormState: () => {},
  setFormStep: () => {},
  setFormValues: () => {},
  strings: {},
};

export default ContactForm;
